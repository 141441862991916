// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: 'AIzaSyCk5-_qyjZLoi_o_c2VJ0U4FhTBzD3VyKw',
    authDomain: 'amokal.com',
    databaseURL: 'https://amokal-app.firebaseio.com',
    projectId: 'amokal-app',
    storageBucket: 'amokal-app.appspot.com',
    messagingSenderId: '533668698621',
    appId: '1:533668698621:ios:2f53e8392c3e4784',
    timestampsInSnapshots: false
  },
  googleApiKey: 'AIzaSyCk5-_qyjZLoi_o_c2VJ0U4FhTBzD3VyKw',
  empresaId : 'RBAszn20bgoxt86iyg2K',
  nombreEmpresa: 'Amokal'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
