import { Component, OnInit } from '@angular/core';
import { AlertService } from '../_services/alert.service';
import { AuthService } from '../_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { UsuarioService } from '../_api/usuarios/usuarios.service';
import { Usuario } from 'src/models/usuario';
@Component({
  selector: 'app-social-signin',
  templateUrl: './social-signin.component.html',
  styleUrls: ['./social-signin.component.css']
})
export class SocialSigninComponent {
  private homeURL = '/home';
  private returnUrl: string;
  users = [];
  user = {};
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private userService: UsuarioService
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  tryGoogleLogin() {
    this.authService.doGoogleLogin().then(
      res => {
        firebase.firestore().collection('usuarios')
                  .get()
                  .then((data) => {
                    data.forEach(item => {
                      this.users.push({
                                        ...item.data() as Usuario,
                                        id: item.id
                                      });
                    });

                    this.setUserInStorage(res);
                    if (this.returnUrl) {
                      this.homeURL = this.returnUrl;
                    }
                    this.router.navigate([this.homeURL]);
                  });
      },
      err => {
        this.alertService.error(err.message);
      }
    );
  }

  tryFacebookLogin() {
    this.authService.doFacebookLogin().then(
      res => {
        this.setUserInStorage(res);
        if (this.returnUrl) {
          this.homeURL = this.returnUrl;
        }
        this.router.navigate([this.homeURL]);
      },
      err => {
        this.alertService.error(err.message);
      }
    );
  }

  tryTwitterLogin() {
    this.authService.doTwitterLogin().then(
      res => {
        this.setUserInStorage(res);
        if (this.returnUrl) {
          this.homeURL = this.returnUrl;
        }
        this.router.navigate([this.homeURL]);
      },
      err => {
        console.log(err);
        this.alertService.error(err.message);
      }
    );
  }

  tryGithubLogin() {
    this.authService.doGitHubLogin().then(
      res => {
            this.setUserInStorage(res);
            if (this.returnUrl) {
              this.homeURL = this.returnUrl;
            }
            this.router.navigate([this.homeURL]);
      },
      err => {
        console.log('error en el login');
        console.log(err);
        this.alertService.error(err.message);
      }
    );
  }

  setUserInStorage(res) {
    if (res.user) {
      localStorage.setItem('currentUser', JSON.stringify(res.user));
      const loggedInUser = JSON.parse(localStorage.getItem('currentUser'));

      this.user = {
                    nombre          : loggedInUser.displayName,
                    photoURL        : loggedInUser.photoURL,
                    uid             : loggedInUser.uid,
                    id              : '',
                    email           : loggedInUser.email,
                    displayName     : loggedInUser.displayName,
                    providerId      : loggedInUser.providerData[0]['providerId'] || 'mail',
                    providerData    : '',
                    photoStorage    : '',
                    isEmailVerified : false,
                    messagingToken  : '',
                    uidGoogle       : '',
                    photoURLSmall   : '',
                    direccion       : '',
                    telefono        : '',
                    movil           : loggedInUser.providerData[0]['phoneNumber'] || '',
                    fechaIns        : firebase.firestore.Timestamp.now(),
                    fechaUpd        : firebase.firestore.Timestamp.now(),
                    estado          : 'A',
                    tipoUsuario     : 'USR',
                    password        : '',
                    confirmPassword : '',
                    desarrollos     : null,
                    badge           : '',
                    completed       : false,
                    isDeleted       : false,
                    createdDate     : firebase.firestore.Timestamp.now()
      };

      if (this.users.length === 0) {
          console.log('no hay usuarios');
          this.userService.createUsuario(this.user, false).subscribe(() => {
            console.log('Usuario agregado exitosamente.');
            localStorage.setItem('currentUser', JSON.stringify(this.user));
          }, (err) => {
            console.log('Error al agregar.');
          });
      } else {
          let userExists = false;
          for (let i = 0; i < this.users.length; i++) {
            const userUid = this.users[i].uid;
            if (res.user.uid === userUid) {
              userExists = true;
              this.user = this.users[i];
              break;
            }
          }
          if (!userExists) {
            console.log('No existe, se agrega');
            this.userService.createUsuario(this.user, false).subscribe(() => {
              console.log('Usuario agregado exitosamente.');
              localStorage.setItem('currentUser', JSON.stringify(this.user));
            }, (err) => {
              console.log('Error', 'Error al agregar.');
            });
          } else {
              localStorage.setItem('currentUser', JSON.stringify(this.user));
          }
      }
    } else {
      localStorage.setItem('currentUser', JSON.stringify(res));
    }
  }
}
