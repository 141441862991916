import { Component } from '@angular/core';

@Component({
  templateUrl: 'serviceconditions.component.html',
  styleUrls: ['./serviceconditions.component.css']
})
export class ServiceConditionsComponent {
  defaultImage = '../assets/images/portrait/small/default.png';
  constructor() {  }
}
