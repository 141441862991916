import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase/app';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { UsuarioService } from '../_api/usuarios/usuarios.service';
import { Usuario } from 'src/models/usuario';

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage = '';
  successMessage = '';
  user = {};
  users = [];
  defaultImage = '../assets/images/portrait/small/default.png';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private userService: UsuarioService) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.userService.getUsuarios().subscribe(users => {
      this.users = users.map(item => {
        return {
          ...item.payload.doc.data() as Usuario,
          id: item.payload.doc.id
        };
      });
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  tryRegister() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;

    this.authService.doRegister(this.registerForm.value).then(
      res => {
        const currentUser = firebase.auth().currentUser;

        this.user = {
          nombre          : this.registerForm.value.firstName,
          photoURL        : this.defaultImage,
          uid             : currentUser.uid,
          id              : '',
          email           : currentUser.email,
          displayName     : this.registerForm.value.firstName,
          providerId      : '',
          providerData    : '',
          photoStorage    : '',
          isEmailVerified : currentUser.emailVerified,
          messagingToken  : '',
          uidGoogle       : '',
          photoUrlSmall   : this.defaultImage,
          direccion       : '',
          telefono        : '',
          movil           : '',
          fechaIns        : firebase.firestore.Timestamp.now(),
          fechaUpd        : firebase.firestore.Timestamp.now(),
          estado          : 'A',
          tipoUsuario     : 'USR',
          password        : '',
          confirmPassword : '',
          desarrollos     : null,
          badge           : '',
          completed       : false,
          isDeleted       : false,
          createdDate     : firebase.firestore.Timestamp.now()
        };

        this.loading = false;

        this.errorMessage = '';
        this.alertService.success('Registro exitoso', true);
        if (this.users.length === 0) {
          this.userService.createUsuario(this.user, false).subscribe(() => {
              console.log('Usuario agregado exitosamente.');
          }, (err) => {
              console.log('Error al agregar.');
          });
        } else if (this.users.length !== 0) {
          let found = false;
          for (let i = 0; i < this.users.length; i++) {
              if (this.users[i].uid === res.user.uid) {
                  found = true;
                  /*
                  currentUser.updateProfile({
                    photoURL: this.defaultImage,
                    displayName: this.registerForm.value.firstName
                  }).then( user => {
                    console.log(user, 'usuario actualizado exitosamente');
                  }, err => {
                    console.log(err);
                  });
                  */
                  break;
              }
          }
          if (!found) {
            this.userService.createUsuario(this.user, false).subscribe(() => {
              console.log('Usuario agregado exitosamente.');
            }, (err) => {
              console.log('Error al agregar.');
            });
          }
        } else {
          console.log('error');
        }
        this.router.navigate(['/login']);
      },
      err => {
        console.log(err);
        this.loading = false;
        this.alertService.error(err.message);
      }
    );
  }
}
