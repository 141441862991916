import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { Usuario } from 'src/models/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  formData: Usuario;
  loggedInUser = JSON.parse(localStorage.getItem('currentUser'));
  ref = firebase.firestore().collection('usuarios');

  constructor(private firestore: AngularFirestore) {
  }

  getUsuarios() {
    return this.firestore.collection('usuarios').snapshotChanges();
  }

  createUsuario(usuario, authUser: boolean): Observable<any> {
    return new Observable((observer) => {
        /*
        const usr = {
          email: 'user@example.com',
          emailVerified: false,
          phoneNumber: '+11234567890',
          password: 'secretPassword',
          displayName: 'John Doe',
          photoURL: 'http://www.example.com/12345678/photo.png',
          disabled: false
        }
        */
        // usuario.password = Math.random().toString(36).slice(-10);
        if (authUser) {
            firebase.auth().createUserWithEmailAndPassword(usuario.email, 'Temporal123')
                          .then((credentials) => {
                                      const ref = firebase.firestore().collection('usuarios');
                                      ref.add(usuario).then((doc) => {
                                          return doc;
                                      }).catch((reason) => { console.log(reason); });
                                  })
                          .catch((error) => {
                                      // Handle Errors here.
                                      const errorCode = error.code;
                                      const errorMessage = error.message;
                                      if (errorCode === 'auth/weak-password') {
                                        alert('La contraseña es muy debil.');
                                      } else {
                                        alert(errorMessage);
                                      }
                                      console.log(error);
                                    });
        } else {
          const ref = firebase.firestore().collection('usuarios');
          ref.add(usuario).then((doc) => {
              return doc;
          }).catch((reason) => { console.log(reason); });
        }
    });
  }

  updateUsuario(id: string, data: any): Observable<any> {
    return new Observable((observer) => {
      this.ref.doc(id).set(data).then(() => {
        observer.next();
      });
    });
  }

  deleteUsuario(id: string): Promise<void> {
      return this.ref.doc(id).delete();
  }
}
